<template>
  <div class="page-404 page__inner container-padding">
    <div class="page-404__wrapper">
      <IconComponent name="logo" />
      <div class="page-404__info">
        <span>Такой страницы пока нет</span>
        <p>но мы подумаем как её использовать, а пока можете...</p>
        <router-link :to="{ name: 'home' }" class="btn btn--main btn--md">Вернуться на главную</router-link>
      </div>
    </div>
    <SliderComponent
      id="shares"
      class="page-404__sale-slider"
      :items="products.data"
      v-slot="{ item, grid }"
      :slider-options="sliderSaleOptions"
      title="Акционные товары"
    >
      <CardComponent :data="item" :grid="grid" />
    </SliderComponent>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import NOT_FOUND_PAGE from "@/graphql/queries/store/discount_products.graphql";
import SliderComponent from "components/SliderComponent.vue";
import CardComponent from "components/CardComponent.vue";
export default {
  components: { CardComponent, SliderComponent, IconComponent },
  async asyncData({ res, store, apollo }) {
    res.status(404);
    await apollo.clients.store
      .query({
        query: NOT_FOUND_PAGE,
        variables: {},
      })
      .then(({ data }) => {
        store.state.not_found_page.discount_products = data.discount_products;
      });
  },
  computed: {
    products() {
      return this.$store.state.not_found_page.discount_products;
    },
  },
  data() {
    return {
      sliderSaleOptions: {
        slidesPerView: 1.1,
        spaceBetween: 10,
        grid: {
          rows: 2,
        },
        breakpoints: {
          540: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 1.6,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
        },
      },
    };
  },
};
</script>
<style lang="stylus">
.page-404 {
  display flex
  flex-direction column
  align-items center
  margin: 0 auto
  margin-top 80px
  gap 18px
  max-width 872px
  padding-bottom 32px

  &__sale-slider {
    height 472px
    max-width 872px
    width 100%
    margin: 0 auto
    +below(1200px) {
      height 420px
    }

    & .slider__title {
      color: var(--gray-800, #3F3B3B);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;

      +below(480px) {
        font-weight: 500;
        font-size: 1.375em;
        line-height: 30px;
      }
    }

    & .slider__header {
      margin-bottom: 24px
    }
  }

  &__wrapper {
    display flex
    flex-direction column
    gap 24px
    padding-bottom: 32px;
    border-bottom: 1px solid var(--theme-border-color, #E3E3E3)
    width 100%
  }

  &__info {
    display flex
    flex-direction column
    gap 8px
    align-items center

    span {
      font-weight: 700;
      font-size: 48px;
      line-height: 150%;
      text-align: center;
      color: var(--gray-900);

      +below(1024px){
        font-size 2em;
        line-height: 120%;
      }

      +below(528px){
        font-size 1.5em;
        line-height: 120%;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: var(--gray-900);
      margin-bottom: 8px

      +below(528px){
        font-size 14px;
      }
    }

    .btn {
      border-radius: 16px;
    }
  }

  +below(1024px) {
    margin 60px 0 0 0
    .section-inner{
      gap 0
    }
  }

  svg {
    height: 70px ;
    max-width: 209px;

    +below(1024px) {
      height: 50px;
    }
  }
}
</style>
